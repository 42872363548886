const _window = $(window);
const body = $('body');

const toggleMegaMenuItem = $('.menu-item--toggle-mega-menu');
let megaMenuTimer;
const megaMenuDelay = 100;



/**
 * Disable click on .menu-item--toggle-mega-menu anchor
 */
/*$('a', toggleMegaMenuItem).on('click', function (e) {
	e.preventDefault();
	return false;
});*/


/**
 * Open submenu on mobile
 */
toggleMegaMenuItem.children('a').on('click', function (e) {
	/*if (matchMedia('(max-width: 991.98px)').matches)
	{
		e.preventDefault();
		e.stopPropagation();
		$(this).closest('.menu-item').toggleClass('open').children('.sub-menu').slideToggle();

		// don't change class when the user clicks on the plus/minus icon
		$('.tcon', $(this)).toggleClass('tcon-transform');
	}*/


	if (matchMedia('(max-width: 991.98px)').matches)
	{
		e.preventDefault();
		e.stopPropagation();
		$(this).parent().toggleClass('open').children('.sub-menu').slideToggle();
	}
});



/**
 * Open the menu when over toggleMegaMenuItem
 */
if (matchMedia('(any-hover: hover)').matches)
{
	toggleMegaMenuItem
		.on('mouseenter', function () {

			if (matchMedia('(min-width: 992px)').matches)
			{
				openMegaMenu();
			}

		})
		.on('mouseleave', function () {

			clearTimeout(megaMenuTimer);

		});
}
/*else
{
	toggleMegaMenuItem
		.on('click', function () {

			openMegaMenu();

		});
}*/


/**
 * Close the menu when over logo and other navigation menu items
 */
if (matchMedia('(any-hover: hover)').matches)
{
	$('#logo, .main-navigation__menu .menu-item:not(.menu-item--toggle-mega-menu)')
		.on('mouseenter', function () {

			if (matchMedia('(min-width: 992px)').matches)
			{
				closeMegaMenu();
			}

		});
}

/**
 * Close the menu when leaving the menu, clear timeout when entering the menu
 */
if (matchMedia('(any-hover: hover)').matches)
{
	$('#mega-menu, #mega-menu-2')
		.on('mouseenter', function () {

			clearTimeout(megaMenuTimer);

		})
		.on('mouseleave', function () {

			if (matchMedia('(min-width: 992px)').matches)
			{
				closeMegaMenu()
			}

		});
}



/**
 * Open the menu
 */
function openMegaMenu() {
	clearTimeout(megaMenuTimer);

	// after hovering for megaMenuDelay, open the menu
	megaMenuTimer = setTimeout(function () {

		body.addClass('mega-menu-open');

	}, megaMenuDelay);
}


/**
 * Close the menu
 */
function closeMegaMenu() {
	clearTimeout(megaMenuTimer);

	// close #mega-menu after megaMenuDelay
	megaMenuTimer = setTimeout(function () {

		body.removeClass('mega-menu-open');

	}, megaMenuDelay);
}


/**
 * Toggle mega menu submenus
 */
$('> a', '#mega-menu-2 .cat-item').on('mouseenter', function (e) {
	e.preventDefault();

	$(this).closest('li').siblings('li').find('.children').removeClass('children--active');

	$(this).next('.children').addClass('children--active');

	return false;
});


$('.children', '#mega-menu-2').each(function () {
	let parentItemTitle = $(this).prev().text();

	$(this).addClass('sub-menu');

	$(this).prepend('<li class="mega-menu__back mega-menu__back--submenu cat-item"><span>' + parentItemTitle + '</span></li>')
});

$('.cat-item', '#mega-menu-2').each(function () {

	$(this).addClass('menu-item');

	if ($(this).children('.children').length > 0)
	{
		$(this).addClass('cat-item-has-children');

		let toggleMenuItem = $('<span class="toggle_menu_item"></span>');

		$('> a', $(this)).append(toggleMenuItem);

		toggleMenuItem.on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			$(this).closest('.cat-item').toggleClass('open').children('.sub-menu').slideToggle();
		});
	}
});

$('.mega-menu__back:not(.mega-menu__back--submenu)').on('click', function () {
	$('.mega-menu--active').removeClass('mega-menu--active');
});

$('.mega-menu__back--submenu').on('click', function () {
	$('.children--active').removeClass('children--active');
});


/**
 * Megamenu on mobile
 */
enquire.register("screen and (max-width: 992.98px)", {
	match:   function () {

		// add classes and elements to .menu-item--toggle-mega-menu
		toggleMegaMenuItem.addClass('menu-item-has-children');

		$(toggleMegaMenuItem).append('<div class="sub-menu"></div>');

		$('> a', toggleMegaMenuItem).append('<span class="toggle_menu_item"></span>');

		$('div[class*=col-]', '#mega-menu, #mega-menu-2').each(function () {
			$('.sub-menu', toggleMegaMenuItem).append($(this).clone(true));
		});

	},
	unmatch: function () {

		// remove classes and elements from .menu-item--toggle-mega-menu
		toggleMegaMenuItem.removeClass('menu-item-has-children');

		$('.toggle_menu_item', toggleMegaMenuItem).remove();

		$('.sub-menu', toggleMegaMenuItem).remove();

	}
});
