const _window = $(window);


//================================= EXTEND COLUMN BLOCK OUTSIDE CONTAINER

// usage <div class="col-md-6 col-extend"> extend div to left </div>
// usage <div class="col-md-6 col-extend col-extend--right"> extend div to right </div>

function colExtend() {
	let windowWidth = _window.width();
	let containerWidth = $('.container').outerWidth();

	$('.col-extend').each(function () {
		let minBreakpoint = $(this).attr('data-extend-min-breakpoint');

		if (windowWidth >= minBreakpoint)
		{
			$(this).removeAttr('style'); // reset width
			let originalWidth = $(this).css('width');
			let additionalWidth = (windowWidth - containerWidth) / 2;
			let itemNewWidth = 'calc(' + originalWidth + ' + ' + additionalWidth + 'px)';

			let direction = ($(this).hasClass('col-extend--right')) ? 'right' : 'left';

			$(this).css({
				'width':            itemNewWidth,
				['margin-' + direction]: '-' + additionalWidth + 'px'
			});
		}
		else
		{
			$(this).removeAttr('style');
		}

	})
}

colExtend();


//================================= EXTEND BG BLOCK OUTSIDE CONTAINER
function bgExtend() {
	let windowWidth = _window.width();
	let containerWidth = $('.container').outerWidth();

	$('.bg-extend').each(function () {
		let minBreakpoint = $(this).attr('data-extend-min-breakpoint');

		if (windowWidth >= minBreakpoint)
		{
			$(this).removeAttr('style'); // reset width
			let originalWidth = '100%';
			let additionalWidth = (windowWidth - containerWidth) / 2;
			let itemNewWidth = 'calc(' + originalWidth + ' + ' + additionalWidth + 'px)';

			let direction = ($(this).hasClass('bg-extend--right')) ? 'right' : 'left';

			$(this).css({
				'width':                 itemNewWidth,
				['margin-' + direction]: '-' + additionalWidth + 'px'
			});
		}
		else
		{
			$(this).removeAttr('style');
		}
	})
}

bgExtend();



_window.on('resizeEnd', function () {

	colExtend();

	bgExtend();

});