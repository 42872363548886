const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';




//================================= TOGGLE POPUP SEARCH

$( '.open-popup-search' ).on( 'click', function(e) {
	e.preventDefault();

	// open popup search
	body.addClass( 'popup-search-open' ).removeClass('menu-open');
	$('#menu-toggle').removeClass('tcon-transform');

	let container = $(".search-popup");

	$('.search-field', container).trigger('focus');

	$('body').on('click',function(event){

		// if the target of the click isn't the container nor a descendant of the container
		if (!container.is(event.target) && container.has(event.target).length === 0)
		{
			body.removeClass( 'popup-search-open' );
			$('body').off('click');
		}
	});

	return false;
} );

$( '.search-reset' ).on( 'click', function(e) {
	e.preventDefault();

	let form = $(this).parents('.search-form');

	// reset search field
	$('.search-field', form).val( '' ).trigger('focus');

	// hide reset button
	$(this).hide();

	// show search button
	$('.search-submit', form).show();

	return false;
} );

$( '#close-popup-search' ).on( 'click', function(e) {
	e.preventDefault();

	// close popup search
	body.removeClass( 'popup-search-open' );

	return false;
} );


/**
 * Autosize textareas
 */

let textareas = document.querySelectorAll('textarea.autosize');

autosize(textareas);

/*
[...textareas].forEach(
	(item, pos) => {
		item.addEventListener('autosize:resized', function(){
			// do somenthing when a textarea changes size
		});
	}
);
*/


/**
 * Open / close woocommerce filters on mobile
 */
$( '.woocommerce-filters-toggle' ).on( 'click', function() {
	body.addClass( 'woocommerce-filters-open' );
} );

$( '.content-sidebar__close' ).on( 'click', function() {
	body.removeClass( 'woocommerce-filters-open' );
} );


/**
 * Marquee
 */
$('.marquee-inner').each(function () {
	$(this).append($(this).contents().clone());
	$(this).append($(this).contents().clone());
});


/**
 * Tabs: activate selected tab
 */
$('.tabs__header-buttons span[data-tab]').off('click').on('click', function () {
	const tab = $(this).attr('data-tab');

	$('[data-tab=' + tab + ']', '.tabs__header').addClass('active').siblings().removeClass('active');

	const activeItem = $('div[data-tab="' + tab + '"]', '.tabs__tabs');
	activeItem.addClass('active');

	let inactiveItems = activeItem.addClass('active').siblings();
	inactiveItems.removeClass('active');

	$('.tabs__tabs').height(activeItem.outerHeight());

	goToByScroll($('.tabs'), -40);
});


/**
 * Drag to scroll tabs header
 */
let tabsHeader = document.querySelector('.tabs__header');

if (tabsHeader !== null)
{
	new ScrollBooster({
		viewport:   document.querySelector('.tabs__header'),
		scrollMode: 'native',
		direction:  'horizontal',
		bounce:     false
	});
}


/**
 * Sticky buttons
 */
/*
let stickyButtons = gsap.timeline();

stickyButtons.to('.sticky-buttons', {
	scrollTrigger: {
		trigger:       'footer',
		start:         'top 100%-=-8', // which means "when the top of the trigger hits -8px above the bottom of the viewport (40px button height - 32px bottom position = -8px)
		toggleActions: 'play none none reset',
		//markers:       true
	},
	position:      'absolute',
	bottom:        $('#site-footer').height() + 40 + 72 // footer height + button height + margin bottom
});
*/


$('nav a', '.module-accordion').on('click', function () {
	$('nav a', '.module-accordion').removeClass('active');
	$(this).addClass('active');
})



/**
 * Cerca agenti
 */
let cercaAgenti = document.querySelector('.cerca-agenti');

if (cercaAgenti !== null)
{
	const regioniProvince = [
		{
			"nome":       "Abruzzo",
			"capoluoghi": [
				"Chieti",
				"L'Aquila",
				"Pescara",
				"Teramo"
			],
			"province":   [
				"CH",
				"AQ",
				"PE",
				"TE"
			]
		},
		{
			"nome":       "Basilicata",
			"capoluoghi": [
				"Matera",
				"Potenza"
			],
			"province":   [
				"MT",
				"PZ"
			]
		},
		{
			"nome":       "Calabria",
			"capoluoghi": [
				"Catanzaro",
				"Cosenza",
				"Crotone",
				"Reggio Calabria",
				"Vibo Valentia"
			],
			"province":   [
				"CZ",
				"CS",
				"KR",
				"RC",
				"VV"
			]
		},
		{
			"nome":       "Campania",
			"capoluoghi": [
				"Avellino",
				"Benevento",
				"Caserta",
				"Napoli",
				"Salerno"
			],
			"province":   [
				"AV",
				"BN",
				"CE",
				"NA",
				"SA"
			]
		},
		{
			"nome":       "Emilia-Romagna",
			"capoluoghi": [
				"Bologna",
				"Ferrara",
				"Forli-Cesena",
				"Modena",
				"Parma",
				"Piacenza",
				"Ravenna",
				"Reggio Emilia",
				"Rimini"
			],
			"province":   [
				"BO",
				"FE",
				"FC",
				"MO",
				"PR",
				"PC",
				"RA",
				"RE",
				"RN"
			]
		},
		{
			"nome":       "Friuli-Venezia Giulia",
			"capoluoghi": [
				"Gorizia",
				"Pordenone",
				"Trieste",
				"Udine"
			],
			"province":   [
				"GO",
				"PN",
				"TS",
				"UD"
			]
		},
		{
			"nome":       "Lazio",
			"capoluoghi": [
				"Frosinone",
				"Latina",
				"Rieti",
				"Roma",
				"Viterbo"
			],
			"province":   [
				"FR",
				"LT",
				"RI",
				"RM",
				"VT"
			]
		},
		{
			"nome":       "Liguria",
			"capoluoghi": [
				"Genova",
				"Imperia",
				"La Spezia",
				"Savona"
			],
			"province":   [
				"GE",
				"IM",
				"SP",
				"SV"
			]
		},
		{
			"nome":       "Lombardia",
			"capoluoghi": [
				"Bergamo",
				"Brescia",
				"Como",
				"Cremona",
				"Lecco",
				"Lodi",
				"Mantova",
				"Milano",
				"Monza e Brianza",
				"Pavia",
				"Sondrio",
				"Varese"
			],
			"province":   [
				"BG",
				"BS",
				"CO",
				"CR",
				"LC",
				"LO",
				"MN",
				"MI",
				"MB",
				"PV",
				"SO",
				"VA"
			]
		},
		{
			"nome":       "Marche",
			"capoluoghi": [
				"Ancona",
				"Ascoli Piceno",
				"Fermo",
				"Macerata",
				"Pesaro e Urbino"
			],
			"province":   [
				"AN",
				"AP",
				"FM",
				"MC",
				"PU"
			]
		},
		{
			"nome":       "Molise",
			"capoluoghi": [
				"Campobasso",
				"Isernia"
			],
			"province":   [
				"CB",
				"IS"
			]
		},
		{
			"nome":       "Piemonte",
			"capoluoghi": [
				"Alessandria",
				"Asti",
				"Biella",
				"Cuneo",
				"Novara",
				"Torino",
				"Verbano-Cusio-Ossola",
				"Vercelli"
			],
			"province":   [
				"AL",
				"AT",
				"BI",
				"CN",
				"NO",
				"TO",
				"VB",
				"VC"
			]
		},
		{
			"nome":       "Puglia",
			"capoluoghi": [
				"Bari",
				"Barletta-Andria-Trani",
				"Brindisi",
				"Foggia",
				"Lecce",
				"Taranto"
			],
			"province":   [
				"BA",
				"BT",
				"BR",
				"FG",
				"LE",
				"TA"
			]
		},
		{
			"nome":       "Sardegna",
			"capoluoghi": [
				"Cagliari",
				"Carbonia-Iglesias",
				"Medio Campidano",
				"Nuoro",
				"Ogliastra",
				"Olbia-Tempio",
				"Oristano",
				"Sassari"
			],
			"province":   [
				"CA",
				"CI",
				"VS",
				"NU",
				"OG",
				"OT",
				"OR",
				"SS"
			]
		},
		{
			"nome":       "Sicilia",
			"capoluoghi": [
				"Agrigento",
				"Caltanissetta",
				"Catania",
				"Enna",
				"Messina",
				"Palermo",
				"Ragusa",
				"Siracusa",
				"Trapani"
			],
			"province":   [
				"AG",
				"CL",
				"CT",
				"EN",
				"ME",
				"PA",
				"RG",
				"SR",
				"TP"
			]
		},
		{
			"nome":       "Toscana",
			"capoluoghi": [
				"Arezzo",
				"Firenze",
				"Grosseto",
				"Livorno",
				"Lucca",
				"Massa e Carrara",
				"Pisa",
				"Pistoia",
				"Prato",
				"Siena"
			],
			"province":   [
				"AR",
				"FI",
				"GR",
				"LI",
				"LU",
				"MS",
				"PI",
				"PT",
				"PO",
				"SI"
			]
		},
		{
			"nome":       "Trentino-Alto Adige",
			"capoluoghi": [
				"Bolzano",
				"Trento"
			],
			"province":   [
				"BZ",
				"TN"
			]
		},
		{
			"nome":       "Umbria",
			"capoluoghi": [
				"Perugia",
				"Terni"
			],
			"province":   [
				"PG",
				"TR"
			]
		},
		{
			"nome":       "Valle d'Aosta",
			"capoluoghi": ["Aosta"],
			"province":   ["AO"]
		},
		{
			"nome":       "Veneto",
			"capoluoghi": [
				"Belluno",
				"Padova",
				"Rovigo",
				"Treviso",
				"Venezia",
				"Verona",
				"Vicenza"
			],
			"province":   [
				"BL",
				"PD",
				"RO",
				"TV",
				"VE",
				"VR",
				"VI"
			]
		}
	];

	const selectRegioni = document.querySelector('select[name=regioni]');
	const selectProvince = document.querySelector('select[name=province]');
	const selectProvinceWrapper = document.querySelector('.select-province');

	for (const key in regioniProvince)
	{
		let opt = document.createElement('option');
		opt.value = regioniProvince[key].nome;
		opt.innerHTML = regioniProvince[key].nome;
		selectRegioni.appendChild(opt);
	}

	selectRegioni.addEventListener('change', function () {
		const regione = selectRegioni.value;

		// remove all select's options except the first
		for (let i = selectProvince.options.length; i > 0; i--)
		{
			selectProvince.remove(i);
		}

		// if the user has selected a regione, show and populate the province select
		if (regione !== '')
		{
			selectProvinceWrapper.style.display = 'block';

			for (const key in regioniProvince)
			{
				if (regioniProvince[key].nome === regione)
				{
					for (const j in regioniProvince[key].capoluoghi)
					{
						let opt = document.createElement('option');
						opt.value = regioniProvince[key].province[j];
						opt.innerHTML = regioniProvince[key].capoluoghi[j];
						selectProvince.appendChild(opt);
					}
				}
			}
		}
		else
		{
			selectProvinceWrapper.style.display = 'none';
		}
	});

	selectProvince.addEventListener('change', function () {
		const provincia = selectProvince.value;

		const agenti = document.getElementsByClassName('agente');

		for (let i = 0; i < agenti.length; i++)
		{
			if (provincia !== '')
			{
				if (agenti.item(i).dataset.province.includes(provincia))
				{
					agenti.item(i).style.display = 'block';
				}
				else
				{
					agenti.item(i).style.display = 'none';
				}
			}
			else
			{
				agenti.item(i).style.display = 'none';
			}

		}
	});
}


let formLavoraConNoi = document.querySelector('#gform_1');

if (formLavoraConNoi !== null)
{
	const selectProvinceContainers = document.querySelectorAll('.gfield.provincia');

	selectProvinceContainers.forEach(item => {

		const selectProvince = item.querySelectorAll('select')[0];
		const selectCitta = item.nextElementSibling.querySelectorAll('select')[0];

		if (selectCitta.options.length === 1)
		{
			selectCitta.setAttribute('disabled', 'disabled');
		}

		selectProvince.addEventListener('change', function () {
			const provincia = selectProvince.value;

			// remove all select's options
			for (let i = selectCitta.options.length; i > 0; i--)
			{
				selectCitta.remove(i);
			}

			// if the user has selected a regione, show and populate the province select
			if (provincia !== '')
			{
				selectCitta.removeAttribute('disabled');

				for (const key in comuni)
				{
					if (key === provincia)
					{
						for (const j in comuni[key].comuni)
						{
							let opt = document.createElement('option');
							opt.value = comuni[key].comuni[j];
							opt.innerHTML = comuni[key].comuni[j];
							selectCitta.appendChild(opt);
						}
					}
				}
			}
			else
			{
				selectCitta.setAttribute('disabled', 'disabled');
			}
		});

	});


	const selectTitoloDiStudio = document.querySelector('.gfield.titolo-di-studio select');
	const selectIndirizzoDiStudio = document.querySelector('.gfield.indirizzo-di-studio select');
	const selectTipologiaDiStudio = document.querySelector('.gfield.tipologia-di-studio select');

	if (selectIndirizzoDiStudio.options.length === 1)
	{
		selectIndirizzoDiStudio.setAttribute('disabled', 'disabled');
	}

	if (selectTipologiaDiStudio.options.length === 1)
	{
		selectTipologiaDiStudio.setAttribute('disabled', 'disabled');
	}

	selectTitoloDiStudio.addEventListener('change', function () {
		const titoloDiStudio = selectTitoloDiStudio.value;

		// remove all select's options
		for (let i = selectIndirizzoDiStudio.options.length; i > 0; i--)
		{
			selectIndirizzoDiStudio.remove(i);
		}

		for (let i = selectTipologiaDiStudio.options.length; i > 0; i--)
		{
			selectTipologiaDiStudio.remove(i);
		}

		if (titoloDiStudio !== '')
		{
			selectIndirizzoDiStudio.removeAttribute('disabled');

			for (const j in titoli_di_studio[titoloDiStudio])
			{
				let opt = document.createElement('option');
				opt.value = j;
				opt.innerHTML = j;
				selectIndirizzoDiStudio.appendChild(opt);
			}
		}
		else
		{
			selectIndirizzoDiStudio.setAttribute('disabled', 'disabled');
		}
	});

	selectIndirizzoDiStudio.addEventListener('change', function () {
		const titoloDiStudio = selectTitoloDiStudio.value;
		const indirizzoDiStudio = selectIndirizzoDiStudio.value;

		// remove all select's options
		for (let i = selectTipologiaDiStudio.options.length; i > 0; i--)
		{
			selectTipologiaDiStudio.remove(i);
		}

		if (titoloDiStudio !== '' && indirizzoDiStudio !== '')
		{
			selectTipologiaDiStudio.removeAttribute('disabled');

			for (const j in titoli_di_studio[titoloDiStudio][indirizzoDiStudio])
			{
				let opt = document.createElement('option');
				opt.value = titoli_di_studio[titoloDiStudio][indirizzoDiStudio][j];
				opt.innerHTML = titoli_di_studio[titoloDiStudio][indirizzoDiStudio][j];
				selectTipologiaDiStudio.appendChild(opt);
			}
		}
		else
		{
			selectTipologiaDiStudio.setAttribute('disabled', 'disabled');
		}
	});


	let inputPrimaEsperienzaLavorativa = $('input[name=input_85]');

	function checkPrimaEsperienzaLavorativa() {
		let primaEsperienzaLavorativa = inputPrimaEsperienzaLavorativa.filter(':checked').val();

		if (primaEsperienzaLavorativa === 'Si')
		{
			$('#field_1_1000').hide();
			$('#field_1_1000').find('input').val('');
			$('#field_1_1000').find('select').prop('selectedIndex',0);
		}
		else
		{
			$('#field_1_1000').show();
		}
	}

	checkPrimaEsperienzaLavorativa();

	inputPrimaEsperienzaLavorativa.on('change', function () {
		checkPrimaEsperienzaLavorativa();
	});
}


/**
 * ON LOAD
 */

_window.on('load', function (event) {

});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/