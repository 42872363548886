const _window = $(window);

let slideshowSwiper = new Swiper('.slideshow .swiper', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

let productsSwiper = new Swiper('.products .swiper', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.products .swiper-button-next',
		prevEl: '.products .swiper-button-prev',
	}
});

let productGallerySwiper = new Swiper('.product-gallery .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  0,
	navigation:    {
		nextEl: '.product-gallery .swiper-button-next',
		prevEl: '.product-gallery .swiper-button-prev',
	},
	pagination:    {
		el: ".swiper-pagination",
	}
});

let heroHomeSwiper = new Swiper('.hero-home', {
	slidesPerView: 'auto',
	pagination:    {
		el: ".swiper-pagination",
	},
	loop:	true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},
});

let homeProductsSwiper = new Swiper('.home .tabs__tabs .products', {
	slidesPerView:  'auto',
	slidesPerGroup: 1,
	pagination:     {
		el: ".swiper-pagination",
	},
	breakpoints:    {
		768: {
			slidesPerGroup: 2,
		},
		992: {
			slidesPerGroup: 4,
		},
	},
});


let gallerySlideshowSwiper = new Swiper('.gallery-display-as-slideshow .swiper', {
	slidesPerView: 'auto',
	speed:         500,
	autoHeight: true,
	/*pagination:     {
		el: ".swiper-pagination",
	},
	loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});